(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/flash-dialog/assets/javascripts/flash-dialog.js') >= 0) return;  svs.modules.push('/components/components/flash-dialog/assets/javascripts/flash-dialog.js');

(function(svs) {
  svs.components = svs.components || {};
  svs.components.flash_dialog =
    svs.components.flash_dialog ||
    (function() {
      'use strict';

      var type = {
        error: 'error',
        info: 'info',
        success: 'success'
      };
      svs = svs || {};

      String.prototype.replaceAt = function(index, character) {
        return (
          this.substr(0, index) +
          character +
          this.substr(index + character.length - 1)
        );
      };
      function htmlEscape(str) {
        return String(str)
          .replace(/"/g, '&quot;')
          .replace(/'/g, '&#39;')
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;');
      }
      function svsMarkup(str) {
        var endtagpos; var starttagpos;
        str = htmlEscape(str);
        str = String(str)
          .replace(/\[br\]/g, '<br>')
          .replace(/\[p\]/g, '<p>')
          .replace(/\[\/p\]/g, '</p>')
          .replace(/\[div\]/g, '<div>')
          .replace(/\[\/div\]/g, '</div>')
          .replace(/\[span\]/g, '<span>')
          .replace(/\[\/span\]/g, '</span>');

        while (
          str.indexOf('[p=') > -1 ||
          str.indexOf('[div=') > -1 ||
          Boolean(~str.indexOf('[span='))
        ) {
          if (str.indexOf('[p=') > -1) {
            starttagpos = str.indexOf('[p=');
            endtagpos = str.indexOf(']', starttagpos);
            str = str.replaceAt(endtagpos, "'>");
            str = str.replace('[p=', "<p class='");
          }
          if (str.indexOf('[div=') > -1) {
            starttagpos = str.indexOf('[div=');
            endtagpos = str.indexOf(']', starttagpos);
            str = str.replaceAt(endtagpos, "'>");
            str = str.replace('[div=', "<div class='");
          }
          if (~str.indexOf('[span=')) {
            starttagpos = str.indexOf('[span=');
            endtagpos = str.indexOf(']', starttagpos);
            str = str.replaceAt(endtagpos, "'>");
            str = str.replace('[span=', "<span class='");
          }
        }
        return str;
      }

      function createMessageArray(message) {
        if (typeof message === 'string') {
          message = message ? message.replace(/(<([^>]+)>)/gi, '') : '';
          return [
            {
              text: svsMarkup(message),
              type: svs.ui.dialog.message.TEXT
            }
          ];
        }

        try {
          if (Array.isArray(message)) {
            message.forEach(function(el) {
              if (el && el.text && typeof el.text === 'string') {
                el.text = el.text ? el.text.replace(/(<([^>]+)>)/gi, '') : '';
                el.text = svsMarkup(el.text);
              }
            });
            return message;
          }
        } catch (err) {
        }
        return [
          {
            text: '',
            type: svs.ui.dialog.message.TEXT
          }
        ];
      }

      function createDialog(type, alert) {
        var branding =
        alert.branding === 'tur' ?
          svs.ui.dialog.branding.TUR :
          svs.ui.dialog.branding.SPORT;
        var dialogType =
        type === 'error' ?
          svs.ui.dialog.type.ERROR :
          svs.ui.dialog.type.DEFAULT;
        var title = alert.title.replace(/(<([^>]+)>)/gi, '');
        var message = createMessageArray(alert.message);
        var area =
        alert.area === 'popup' ?
          svs.ui.dialog.area.POPUP :
          svs.ui.dialog.area.TOASTER;
        var dialog;
        var dialogOptions = {
          branding: branding,
          area: area,
          autoClose: alert.time ? alert.time : 0,
          title: title,
          type: dialogType,
          message: message,
          actions: [
            {
              title: 'Ok',
              callback: function() {
                dialog && dialog.close();
              }
            }
          ]
        };

        if (alert.icon) {
          dialogOptions.icon = alert.icon;
        }

        dialog = new svs.ui.dialog.Confirm(dialogOptions);

        setTimeout(function() {
          svs.ui.dialog.api.add(dialog);
        }, 300);
      }


      function checkForFlashMessages() {
        svs.core.flash.flush(function(messages) {
          var dialogMessages = [];
          Object.keys(messages).forEach(function(messageType) {
            messages[messageType].forEach(function(message) {
              if (type[messageType]) {
                if (message.title) {
                  message.type = messageType;
                  dialogMessages.push(message);
                }
              }
            });
          });

          if (dialogMessages.length) {
            svs.core.module.load('/cl/ui/dialog', null, function() {
              dialogMessages.forEach(function(message) {
                createDialog(message.type, message);
              });
            });
          }
        });
      }

      checkForFlashMessages();

      return {
        checkForFlashMessages: checkForFlashMessages
      };
    })();
})(svs);


 })(window);